<template>
  <TTView>
    <VRow>
      <VCol>
        <RouteCard :route="route" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_ROUTE } from '../../components/sysadmin/common';
import RouteCard from '../../components/sysadmin/RouteCard.vue';

export default {
  name: 'RoutesView',

  components: {
    RouteCard,
  },

  inject: ['Names'],

  data() {
    return {
      route: { ...DEFAULT_ROUTE },
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;

      const { routeId: id } = this.$route.params;

      this.$di.api.Sysadmin
        .getRoute(id)
        .then((response) => {
          this.route = response;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
